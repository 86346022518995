@import "~bootstrap/scss/bootstrap";

body{
	font-size:85%;
	background-color: #FAFBFE !important;
	margin-top:180px;
}

.header{
	border-top:5px solid red;
}
.navbar-brand{
	min-width:400px;
	text-align: center;
}
.navbar-brand img{
	max-width: 330px;
}
.header-nav{
}
.header-nav .container{
	background:transparent;
}
.header-nav .nav{
}
.header-nav .nav-item{
	font-size: 0.7rem;
	background:#FFF;
	border-radius: .5rem .5rem 0 0;
}
.header-nav .nav-item .nav-link {
	display: block;
	padding: 0.5rem;
	color: #0d6efd;
	text-decoration: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.tagline{
	font-size: 0.85rem;
}

.container{
	background:#FFF;
}

.page-header {
	text-indent: 10px;
	position: relative;
	font-size: 1.1rem;
	line-height: 3;
  margin-bottom: 20px;
  border-bottom: 3px solid #DDD;
}
.page-header :before {
	position: absolute;
  bottom: -3px;
  left: 0;
  width: 200px;
  height: 3px;
  content: '';
  border-radius: 3px;
  background: rgb(255, 0, 0);
}

.card-header{
	background-color: #0d6efd;
	border-bottom: none;
}

.feature h5{
	border-bottom: 1px solid #DDD;
}


.plain-text{
	background-color: #FFF !important;
	border:none !important;
}


.extrafield-list dt{
	font-weight: normal;
	line-height: 2.8;
	padding-right: 10px;
	max-height: 34px;
	min-height: 34px;
	border-bottom: 1px solid #DDD;
	text-align: right;
}
.extrafield-list dd{
	line-height: 2.8;
	text-indent:10px;
	min-height: 34px;
	max-height: 34px;
	border-bottom: 1px solid #DDD;
}
.extrafield-list dt:nth-of-type(even){
	background-color: #FDFDFD;
	border-bottom: 1px solid #DDD;
}
.extrafield-list dd:nth-of-type(even){
	background-color: #FDFDFD;
	border-bottom: 1px solid #DDD;
}
.extrafield-list dt:last-of-type {
	border-bottom: none;
}
.extrafield-list dd:last-child{
	border-bottom: none;
}
